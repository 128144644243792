/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$about-hair-pic-name: 'about-hair-pic';
$about-hair-pic-x: 0px;
$about-hair-pic-y: 459px;
$about-hair-pic-offset-x: 0px;
$about-hair-pic-offset-y: -459px;
$about-hair-pic-width: 91px;
$about-hair-pic-height: 126px;
$about-hair-pic-total-width: 405px;
$about-hair-pic-total-height: 1302px;
$about-hair-pic-image: 'sprite.png';
$about-hair-pic: (0px, 459px, 0px, -459px, 91px, 126px, 405px, 1302px, 'sprite.png', 'about-hair-pic', );
$article-arrow-white-name: 'article-arrow-white';
$article-arrow-white-x: 0px;
$article-arrow-white-y: 151px;
$article-arrow-white-offset-x: 0px;
$article-arrow-white-offset-y: -151px;
$article-arrow-white-width: 15px;
$article-arrow-white-height: 25px;
$article-arrow-white-total-width: 405px;
$article-arrow-white-total-height: 1302px;
$article-arrow-white-image: 'sprite.png';
$article-arrow-white: (0px, 151px, 0px, -151px, 15px, 25px, 405px, 1302px, 'sprite.png', 'article-arrow-white', );
$article-arrow-name: 'article-arrow';
$article-arrow-x: 0px;
$article-arrow-y: 124px;
$article-arrow-offset-x: 0px;
$article-arrow-offset-y: -124px;
$article-arrow-width: 15px;
$article-arrow-height: 25px;
$article-arrow-total-width: 405px;
$article-arrow-total-height: 1302px;
$article-arrow-image: 'sprite.png';
$article-arrow: (0px, 124px, 0px, -124px, 15px, 25px, 405px, 1302px, 'sprite.png', 'article-arrow', );
$calendar-closed-name: 'calendar-closed';
$calendar-closed-x: 0px;
$calendar-closed-y: 205px;
$calendar-closed-offset-x: 0px;
$calendar-closed-offset-y: -205px;
$calendar-closed-width: 22px;
$calendar-closed-height: 25px;
$calendar-closed-total-width: 405px;
$calendar-closed-total-height: 1302px;
$calendar-closed-image: 'sprite.png';
$calendar-closed: (0px, 205px, 0px, -205px, 22px, 25px, 405px, 1302px, 'sprite.png', 'calendar-closed', );
$calendar-name: 'calendar';
$calendar-x: 0px;
$calendar-y: 178px;
$calendar-offset-x: 0px;
$calendar-offset-y: -178px;
$calendar-width: 22px;
$calendar-height: 25px;
$calendar-total-width: 405px;
$calendar-total-height: 1302px;
$calendar-image: 'sprite.png';
$calendar: (0px, 178px, 0px, -178px, 22px, 25px, 405px, 1302px, 'sprite.png', 'calendar', );
$fb-icon-name: 'fb-icon';
$fb-icon-x: 0px;
$fb-icon-y: 265px;
$fb-icon-offset-x: 0px;
$fb-icon-offset-y: -265px;
$fb-icon-width: 31px;
$fb-icon-height: 31px;
$fb-icon-total-width: 405px;
$fb-icon-total-height: 1302px;
$fb-icon-image: 'sprite.png';
$fb-icon: (0px, 265px, 0px, -265px, 31px, 31px, 405px, 1302px, 'sprite.png', 'fb-icon', );
$in-icon-name: 'in-icon';
$in-icon-x: 0px;
$in-icon-y: 232px;
$in-icon-offset-x: 0px;
$in-icon-offset-y: -232px;
$in-icon-width: 31px;
$in-icon-height: 31px;
$in-icon-total-width: 405px;
$in-icon-total-height: 1302px;
$in-icon-image: 'sprite.png';
$in-icon: (0px, 232px, 0px, -232px, 31px, 31px, 405px, 1302px, 'sprite.png', 'in-icon', );
$m-nav-toggle-name: 'm-nav-toggle';
$m-nav-toggle-x: 0px;
$m-nav-toggle-y: 74px;
$m-nav-toggle-offset-x: 0px;
$m-nav-toggle-offset-y: -74px;
$m-nav-toggle-width: 29px;
$m-nav-toggle-height: 22px;
$m-nav-toggle-total-width: 405px;
$m-nav-toggle-total-height: 1302px;
$m-nav-toggle-image: 'sprite.png';
$m-nav-toggle: (0px, 74px, 0px, -74px, 29px, 22px, 405px, 1302px, 'sprite.png', 'm-nav-toggle', );
$m-top-nav-close-name: 'm-top-nav-close';
$m-top-nav-close-x: 0px;
$m-top-nav-close-y: 98px;
$m-top-nav-close-offset-x: 0px;
$m-top-nav-close-offset-y: -98px;
$m-top-nav-close-width: 24px;
$m-top-nav-close-height: 24px;
$m-top-nav-close-total-width: 405px;
$m-top-nav-close-total-height: 1302px;
$m-top-nav-close-image: 'sprite.png';
$m-top-nav-close: (0px, 98px, 0px, -98px, 24px, 24px, 405px, 1302px, 'sprite.png', 'm-top-nav-close', );
$metro-icon-name: 'metro-icon';
$metro-icon-x: 0px;
$metro-icon-y: 0px;
$metro-icon-offset-x: 0px;
$metro-icon-offset-y: 0px;
$metro-icon-width: 19px;
$metro-icon-height: 13px;
$metro-icon-total-width: 405px;
$metro-icon-total-height: 1302px;
$metro-icon-image: 'sprite.png';
$metro-icon: (0px, 0px, 0px, 0px, 19px, 13px, 405px, 1302px, 'sprite.png', 'metro-icon', );
$phone-icon-name: 'phone-icon';
$phone-icon-x: 0px;
$phone-icon-y: 15px;
$phone-icon-offset-x: 0px;
$phone-icon-offset-y: -15px;
$phone-icon-width: 16px;
$phone-icon-height: 16px;
$phone-icon-total-width: 405px;
$phone-icon-total-height: 1302px;
$phone-icon-image: 'sprite.png';
$phone-icon: (0px, 15px, 0px, -15px, 16px, 16px, 405px, 1302px, 'sprite.png', 'phone-icon', );
$scope-horz-name: 'scope-horz';
$scope-horz-x: 0px;
$scope-horz-y: 587px;
$scope-horz-offset-x: 0px;
$scope-horz-offset-y: -587px;
$scope-horz-width: 405px;
$scope-horz-height: 307px;
$scope-horz-total-width: 405px;
$scope-horz-total-height: 1302px;
$scope-horz-image: 'sprite.png';
$scope-horz: (0px, 587px, 0px, -587px, 405px, 307px, 405px, 1302px, 'sprite.png', 'scope-horz', );
$scope-vert-name: 'scope-vert';
$scope-vert-x: 0px;
$scope-vert-y: 896px;
$scope-vert-offset-x: 0px;
$scope-vert-offset-y: -896px;
$scope-vert-width: 307px;
$scope-vert-height: 406px;
$scope-vert-total-width: 405px;
$scope-vert-total-height: 1302px;
$scope-vert-image: 'sprite.png';
$scope-vert: (0px, 896px, 0px, -896px, 307px, 406px, 405px, 1302px, 'sprite.png', 'scope-vert', );
$slide-arrow-name: 'slide-arrow';
$slide-arrow-x: 0px;
$slide-arrow-y: 52px;
$slide-arrow-offset-x: 0px;
$slide-arrow-offset-y: -52px;
$slide-arrow-width: 34px;
$slide-arrow-height: 20px;
$slide-arrow-total-width: 405px;
$slide-arrow-total-height: 1302px;
$slide-arrow-image: 'sprite.png';
$slide-arrow: (0px, 52px, 0px, -52px, 34px, 20px, 405px, 1302px, 'sprite.png', 'slide-arrow', );
$tw-icon-name: 'tw-icon';
$tw-icon-x: 0px;
$tw-icon-y: 331px;
$tw-icon-offset-x: 0px;
$tw-icon-offset-y: -331px;
$tw-icon-width: 31px;
$tw-icon-height: 31px;
$tw-icon-total-width: 405px;
$tw-icon-total-height: 1302px;
$tw-icon-image: 'sprite.png';
$tw-icon: (0px, 331px, 0px, -331px, 31px, 31px, 405px, 1302px, 'sprite.png', 'tw-icon', );
$vk-icon-name: 'vk-icon';
$vk-icon-x: 0px;
$vk-icon-y: 298px;
$vk-icon-offset-x: 0px;
$vk-icon-offset-y: -298px;
$vk-icon-width: 31px;
$vk-icon-height: 31px;
$vk-icon-total-width: 405px;
$vk-icon-total-height: 1302px;
$vk-icon-image: 'sprite.png';
$vk-icon: (0px, 298px, 0px, -298px, 31px, 31px, 405px, 1302px, 'sprite.png', 'vk-icon', );
$watch-icon-name: 'watch-icon';
$watch-icon-x: 0px;
$watch-icon-y: 33px;
$watch-icon-offset-x: 0px;
$watch-icon-offset-y: -33px;
$watch-icon-width: 17px;
$watch-icon-height: 17px;
$watch-icon-total-width: 405px;
$watch-icon-total-height: 1302px;
$watch-icon-image: 'sprite.png';
$watch-icon: (0px, 33px, 0px, -33px, 17px, 17px, 405px, 1302px, 'sprite.png', 'watch-icon', );
$works-arrow-name: 'works-arrow';
$works-arrow-x: 0px;
$works-arrow-y: 364px;
$works-arrow-offset-x: 0px;
$works-arrow-offset-y: -364px;
$works-arrow-width: 48px;
$works-arrow-height: 93px;
$works-arrow-total-width: 405px;
$works-arrow-total-height: 1302px;
$works-arrow-image: 'sprite.png';
$works-arrow: (0px, 364px, 0px, -364px, 48px, 93px, 405px, 1302px, 'sprite.png', 'works-arrow', );
$spritesheet-width: 405px;
$spritesheet-height: 1302px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($about-hair-pic, $article-arrow-white, $article-arrow, $calendar-closed, $calendar, $fb-icon, $in-icon, $m-nav-toggle, $m-top-nav-close, $metro-icon, $phone-icon, $scope-horz, $scope-vert, $slide-arrow, $tw-icon, $vk-icon, $watch-icon, $works-arrow, );
$spritesheet: (405px, 1302px, 'sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(../../src/i/#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
