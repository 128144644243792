@import 'sprite';
@import 'settings';

body {
  &.overlay-wrap {
    overflow: hidden;
    .overlay {
      display: block;
    }
  }
}

main {
  background: url(../../src/i/main-bg00.jpg);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}


.main-header {
  .m-top-nav-toggle {
    display: none;
    @include sprite($m-nav-toggle);
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 20px;
    &.m-top-nav-close {
      @include sprite($m-top-nav-close);
      z-index: 2;
      right: 40px;
      cursor: pointer;
    }
  }
  main.inner & {
    border-bottom: 2px solid #c5c6ca;
    margin-bottom: 34px;
    padding: 12px 0 19px;
    .m-top-nav-toggle.m-top-nav-close {
      right: 20px;
    }
  }
  @include centered-1240;
  padding: 12px 0 19px;
  color: $txt-clr;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-items: center;
  .logo-box {
    display: inline-flex;
    width: 50%;
    &.logo-box-visible {
      position: relative;
      z-index: 2;
      padding-left: 20px;
      .main-logo {
        color: #fff;
      }
      em {
        color: #fff;
      }
    }
    .main-logo {
      margin-right: 8.5%;
      font: 30px $ff-annabelle;
      color: $txt-clr;

      &:hover {
        text-decoration: none;
      }
    }
    em {
      font: 15px $ff-agora-reg;
      align-self: center;
      white-space: nowrap;
    }
  }
  .top-phones {
    display: inline-flex;
    width: 50%;
    justify-content: flex-end;
    .branch-box {
      width: 50%;
      &:first-child {
        //margin-right: 4.8%;
      }
    }
    .branch-name {
      font: 16px $ff-agora-lgt;
      margin-bottom: 8px;
      display: block;
      &:before {
        @include sprite($metro-icon);
        content: '';
        display: inline-block;
        margin-right: 8px;
      }
    }
    .branch-phones {
      font-size: 0;
      span {
        flex-basis: 50%;
        color: $sec-clr;
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        &:first-child {
          margin-right: 4%;
        }
        b {
          font: 21px $ff-agora-med;
          color: $txt-clr-sec;
          margin-left: 5px;
        }
      }
    }
  }
  .top-nav {
    width: 70%;
    margin-top: 10px;
    position: relative;
    transition: background .5s;
    &.m-top-nav-visible {
      display: block;
      position: absolute;
      background: #555659;
      z-index: 1;
      margin-top: 0;
      top: 0;
      left: 20px;
      //left: 0;
      width: 728px;
      padding: 84px 5% 25px;
      box-sizing: border-box;
      @include animation('bg-fading 1s');
      a {
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
    }
    ul {
      li {
        &.current-menu-item {
          a {
            color: $sec-clr;
            text-decoration: none;
            cursor: default;
          }
        }
      }
    }
    a {
      color: $txt-clr;
      transition: color .4s;
      text-transform: uppercase;
      font: 13px $ff-agora-black;
      margin-right: 2.3%;
      &:last-child {
        margin-right: 0;
      }
      &:hover, &.current-page {
        color: $sec-clr;
        text-decoration: none;
      }
    }
  }
  .top-contact-box {
    width: 30%;
    margin-top: 10px;
    text-align: right;
    a {
      font: 16px $ff-agora-lgt;
      color: $txt-clr-sec;
      margin-right: 4%;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.main-content {
  flex-grow: 1;
  .teaser-wrap {
    position: relative;
    @include centered-1240;
    overflow: hidden;
    max-height: 501px;
    margin-bottom: 40px;
    .tp-leftarrow.uranus.tparrows:before, .tp-rightarrow.uranus.tparrows:before {
      color: #fff !important;
    }
    a:hover {
      text-decoration: none;
    }
    .slides-wrap {
      width: 100%;
    }
    .owl-theme {
      .owl-controls {
        text-align: left;
        margin-top: 0;
        position: relative;
        top: -40px;
        .owl-pagination {
          .owl-page {
            &:first-child {
              span {
                margin-left: 0;
              }
            }
            span {
              width: 26px;
              height: 5px;
              border-radius: 0;
              background: $txt-clr-sec;
              color: $txt-clr-sec;
              margin: 0 5px;
            }
            &.active {
              span {
                background: #fff;
              }
            }
          }
        }
      }}
    img {
      width: 100%;
      //height: 501px;
    }
    h3 {
      //position: absolute;
      //top: 277px;
      line-height: 30px !important;
      //max-width: 50%;
      span {
        display: block;
        font: 49px $ff-marta;
        line-height: normal !important;
        color: #fff;
        background: rgba(179, 155, 155, .8);
        padding: 2px 20px;
        margin-bottom: 3px;
        display: inline-block;
      }
    }
  }
  .gallery-headers {
    @include centered-1240;
    display: flex;
    align-items: flex-start;
    margin-bottom: 14px;
    & + .box-fixed-trigger {
      width: 100%;
      &.extra {
        padding-top: 51px;
      }
    }
    &.fixed-nav {
      position: fixed;
      width: 1280px;
      padding: 0;
      top: 0;
      left: 50%;
      margin-left: -620px;
      z-index: 1000;
      width: 1240px;
      //background: #fff;
    }
    h3 {
      width: 32%;
      margin-bottom: 0;
      &:nth-child(2) {
        margin: 0 2%;
      }
      a {
        display: block;
        text-align: center;
        font: 14px $ff-agora-black;
        color: $txt-clr;
        text-transform: uppercase;
        background: $for-clr;
        padding: 16px 0 12px;
        border-bottom: 4px solid rgba(154, 138, 136, .6);
        transition: background .4s, color .4s;
        &:hover, &.current {
          text-decoration: none;
          background: $trd-clr;
          color: #fff;
          border-color: #eae7e0;
        }
        &.current {
          cursor: default;
        }
      }
    }
  }
  .galleries-wrap {
    @include centered-1240;
    display: flex;
    margin-bottom: 48px;
    text-align: center;
    padding-top: 37px;
    &.rev-gallery-vertical {
      margin: 40px auto 80px;
      .single-gallery {
        border: 3px solid #a9abae;
      }
      .tp-leftarrow.uranus.tparrows:before {
        transform: rotate(-90deg);
        font-weight: bold;
      }
      .tp-rightarrow.uranus.tparrows:before {
        transform: rotate(-90deg);
        font-weight: bold;
      }
    }
    .single-gallery {
      position: relative;
      width: 32%;
      font-size: 0;
      //max-height: 596px;
      //overflow: hidden;
      &.gallery-cntr {
        margin: 0 2.096%;
      }
      a.single-gallery-pic {
        //margin-bottom: 22px;
        display: inline-block;
        position: relative;
        width: 100%;
        //box-sizing: border-box;
        &:after {
          border: none;
          position: absolute;
          top: 3px;
          left: 3px;
          display: none;
          content: '';
          background: rgba(0, 0, 0, .5) url(../../src/i/gallery-pic-overlay.png) 50% 50% no-repeat;
          width: calc(100% - 6px);
          height: calc(100% - 6px);
          z-index: 1;
          transition: all .5s;
        }
        &:hover {
          &:after {
            display: block;
          }
        }
        img {
          border: 3px solid #a9abae;
          box-sizing: border-box;
          width: 100%;
          //height: 281px;
          max-width: 100%;
        }
      }
      span {
        display: inline-block;
        @include sprite($slide-arrow);
        cursor: pointer;
        background-color: transparent;
        margin-bottom: 10px;
        &.single-gallery-next {
          transform: rotate(180deg);
          margin: 0;
        }
      }
    }
/*    .elastislide-vertical {
      padding: 40px 0;
      ul {
        min-height: 596px !important;
        li {
          a {
            img {
              border: 3px solid #a9abae;
            }
          }
        }
      }
    }*/
  }
  .about-box {
    width: 1200px;
    padding: 44px 20px 0;
    background: $fst-clr;
    text-align: center;
    margin-top: 90px;
    h1 {
      color: $trd-clr;
      text-align: center;
      width: 100%;
    }
    img {
      margin-right: 3.22%;
      float: left;
    }
    article {
      width: 88.54%;
      overflow: hidden;
      text-align: left;
      margin-bottom: 24px;
    }
    .btn-sec {
      margin-top: 45px;
      display: inline-block;
    }
  }
  .video-box {
    @include centered-1240;
    padding: 64px 0 95px;
    display: flex;
    &.removed {
      display: none;
    }
    div {
      &:first-child {
        margin-right: 27px;
      }
      h3 {
        font: 19px $ff-agora-bld;
        margin-bottom: 19px;
      }
    }
  }
  .works-gallery {
    @include centered-1240;
    text-align: center;
    padding: 32px 0 0px !important;
    h2 {
      text-align: center;
    }
    .works {
      font-size: 0;
      width: 1020px;
      margin: 0 auto 36px;
      position: relative;
      .owl-controls {
        margin-top: 0;
        position: absolute;
        top: 50%;
        margin-top: -46px;
        width: 100%;
        .owl-buttons {
          .owl-prev, .owl-next {
            @include sprite($works-arrow);
            background-color: transparent;
            padding: 0;
            position: absolute;
            top: 0;
          }
          .owl-next {
            transform: rotate(180deg);
            right: -66px;
          }
          .owl-prev {
            left: -66px;
          }
        }
      }
      a {
        width: 200px;
        display: inline-block;
        img {
          width: 200px;
          height: 292px;
        }
      }
    }
  }
  .extra-cont-box {
    background: #867475;
    padding: 46px 0;
    color: #fff;
    margin: 44px 0;
    .cont-box {
      h3 {
        color: #fff;
      }
      width: 992px;
      //padding: 0 136px 0 112px;
      padding: 0;
      display: flex;
      margin: 0 auto;
      p {
        color: #fff;
      }
      .wysiwyg {
        &:first-child {
          width: 48%;
          margin-right: 53px;
          display: flex;
          flex-direction: column;
          p {
            //flex-grow: 1;
            & + .btn-sec {
              width: 324px;
            }
          }
        }
        &:last-child {
          width: 50%;
        }
        table.home-page-prices {
          td {
            border-width: 1px;
            padding: 8px 10px;
            &:nth-child(even) {
              width: 100px;
            }
          }
        }
      }
      .lnk-on-right, .lnk-on-left {
        color: #fff;
        margin-top: 10px;
      }
    }
  }
  .contacts-box {
    @include centered-1240;
    padding: 10px 0 40px;
    h2 {
      margin-bottom: 0;
    }
    address {
      font-style: normal;
      max-width: 60%;
      //margin: 0 auto;
      .desc {
        font: 16px $ff-lato-reg;
        b {
          font-family: $ff-lato-black;
        }
      }
      em {
        font: 19px $ff-agora-bld;
        color: $txt-clr;
        display: block;
        margin-bottom: 10px;
      }
      p.extra {
        font: 16px $ff-lato-black;
        margin: 33px 0;
      }
      .contacts-phones {
        .down-phone {
          font: 16px $ff-agora-lgt;
          color: $sec-clr;
          &:active, &:hover, &:visited {
            border: none;
            text-decoration: none;
          }
          &:first-child {
            margin-right: 8px;
          }
          b {
            font: 27px $ff-agora-med;
            color: #000;
            vertical-align: middle;
          }
        }
        a {
          display: block;
          color: $txt-clr;
        }
      }
    }
  }
}

.certificates-box {
  display: flex;
  margin-bottom: 36px;
  &:last-of-type {
    padding-bottom: 80px;
    border-bottom: 2px solid #c5c6ca;
  }
  figure {
    margin: 0;
    &.horizontal-scope {
      align-self: flex-end;
      a {
        @include sprite($scope-horz);
        width: 405px;
        height: 307px;
        &:after {
          top: 39px;
          left: 39px;
          width: 328px;
          height: 231px;
        }
      }
    }
    &:nth-child(2) {
      flex-grow: 1;
      text-align: center;
    }
    a {
      @include sprite($scope-vert);
      position: relative;
      width: 307px;
      height: 406px;
      display: inline-block;
      &:after {
        position: absolute;
        top: 39px;
        left: 40px;
        display: none;
        content: '';
        background: rgba(0, 0, 0, .5) url(../../src/i/gallery-pic-overlay.png) 50% 50% no-repeat;
        width: 229px;
        height: 328px;
        z-index: 2;
        transition: all .5s;
      }
      &:hover {
        &:after {
          display: block;
        }
      }
      img {
        position: absolute;
        top: 39px;
        left: 40px;
        z-index: 1;
      }
    }
    figcaption {
      text-align: center;
      //display: inline-block;
      margin-top: 20px;
      font: 16px $ff-lato-reg;
      width: 100%;
    }
  }
}

.main-footer {
  background: url(../../src/i/footer-bg00.jpg) 50% 50% /cover no-repeat;
  min-height: 307px;
  .footer {
    @include centered-1240;
    padding: 37px 0 34px;
    display: flex;
    align-items: flex-start;
    ul {
      border-right: 1px solid #c9cbd0;
      padding: 12px 6.04% 12px 0;
      margin: 0 6.04% 0 0;
      &:last-of-type {
        margin-right: 0;
        border-right: none;
        padding-right: 0;
        flex-grow: 1;
      }
      li {
        margin-bottom: 12px;
        a {
          color: #fff;
          font: 16px $ff-agora-med;
        }
      }
    }
    .social-box {
      font-size: 0;
      padding-top: 16px;
      flex-basis: 156px;
      display: inline-flex;
      .social-icon {
        width: 31px;
        height: 31px;
        display: inline-block;
        margin-right: 10px;
        opacity: .6;
        transition: opacity .7s;
        &:hover {
          opacity: 1;
        }
        &:last-of-type {
          margin-right: 0;
        }
        &.tw {
          @include sprite($tw-icon);
        }
        &.fb {
          @include sprite($fb-icon);
        }
        &.in {
          @include sprite($in-icon);
        }
        /*
        &.yo {
          @include sprite($yo-icon);
        }
        */
        &.vk {
          @include sprite($vk-icon);
        }
      }
    }
  }
}

.bx-wrapper {
  .bx-viewport {
    box-shadow: none;
    border: none;
    left: 0;
    background: none;
  }
  .bx-controls {
    .bx-controls-direction {
      a {
        position: absolute;
        @include sprite($slide-arrow);
        width: 34px;
        height: 20px;
        margin-left: -17px;
        left: 50%;
        z-index: 100;
        &.bx-prev {
          transform: rotate(180deg);
          top: 105%
        }
        &.bx-next {
          top: -14px;
        }
      }
    }
  }
}

.wysiwyg {
  margin: 0 0 30px;
  &:after {
    content: '.';
    display: block;
    clear: both;
    font: 0/0 a;
    height: 0;
    visibility: hidden;
  }
  @include centered-1240;
  p {
    font: 16px $ff-lato-reg;
    margin-bottom: 20px;
    color: #000;
    text-align: left;
    line-height: 19px;
  }
  h1 {
    font: 60px $ff-agora-bld;
    margin-bottom: 30px;
    color: $sec-clr;
  }
  h3 {
    font: 30px $ff-agora-bld;
    text-align: left;
    margin-bottom: 15px;
    color: $txt-clr;
  }
  ul {
    margin-bottom: 20px;
    padding-left: 20px;
    li {
      text-align: left;
      font: 16px $ff-lato-reg;
      line-height: 19px;
      margin-bottom: 9px;
      list-style: disc outside;
    }
  }
  ol {
    margin-top: 10px;
    li {
      list-style: decimal inside;
    }
  }
  table {
    &.pricing-table {
      td {
        width: 25%;
      }
    }
    tr {
      th, td {
        border: 3px solid #d9d7cf;
        padding: 25px 17px;
        b {
          font: 21px $ff-agora-bld;
          color: $txt-clr-sec;
          &.service {
            display: block;
            margin-bottom: 14px;
          }
        }
        span {
          font: 19px $ff-lato-lgt;
          color: $txt-clr;
          &.service-price {
            font-size: 16px;
          }
        }
      }
    }
  }
  img {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

form {
  border: 1px solid $sec-clr;
  padding: 32px 36px 0;
  margin: 40px auto;
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 618px;
  &.call-back-form {
    fieldset {
      width: 300px;
      text-align: left;
      &:nth-child(2n+2) {
        margin-right: 16px;
      }
      p {
        margin: 0;
      }
      .g-recaptcha {
        margin-top: 13px;
      }
    }
  }
  label {
    color: $txt-clr;
    font: 16px $ff-agora-bld;
    display: block;
    margin-bottom: 12px;
    color: $trd-clr;
    .error-field {
      color: #ff5d58;
      input, textarea {
        border-color: #ff5d58;
      }
    }
  }
  input[type=text], input[type=tel], input[type=email], input[type=password], textarea {
    border: 1px solid $sec-clr;
    font: 15px $ff-lato-reg;
    display: block;
    margin: 13px 0;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    &:focus {
      outline: none;
      background: #f8f3f3;
    }
  }
  .captcha-pic {
    border: 1px solid #adadad;
    margin: 13px 0;
    & + input[type=text] {
      display: inline-block;
      width: auto;
      max-width: 150px;
      float: right;
    }
  }
  [type=submit] {
    margin-top: 33px;
    width: 100%;
  }
}

.form-msg-wrap {
  display: block;
  width: 100%;
  clear: both;
  margin: 10px 0 0;
  .thanx-box {
    display: none;
    text-align: center;
    opacity: 0;
    transition: all 1s;
    h6 {
      color: #7ab833;
      font-size: 16px;
    }
    &.showed-box {
      display: block;
      opacity: 1;
      @include animation('fading 1s');
    }
  }
  .error-box {
    @extend .thanx-box;
    h6 {
      color: #f00;
      font-size: 16px;
    }
  }
}

@include keyframes(fading) {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}

.side-left {
  float: left;
  margin-right: 28px;
}

.full-width {
  width: 100%;
  margin-bottom: 40px;
}

.breadcrumbs {
  @include centered-1240;
  margin-bottom: 34px;
  font-size: 0;
  li {
    display: inline-block;
    a {
      color: $txt-clr;
      font: 12px $ff-agora-lgt;
      &:after {
        content: '\2192';
        display: inline-block;
        margin: 0 4px 0 3px;
        font-size: 13px;
        color: $sec-clr;
      }
    }
    .current-page {
      color: $sec-clr;
      font: 12px $ff-agora-lgt;
    }
  }
}

.full-story {
  font: 16px $ff-agora-lgt;
  color: $txt-clr;
  display: block;
  margin-bottom: 22px;
  &:after {
    color: $sec-clr;
    content: '\2192';
    display: inline-block;
    margin-left: 4px;
  }
}

.paging {
  @include centered-1240;
  font-size: 0;
  margin-top: 50px;
  &.centered {
    text-align: center;
  }
  li {
    display: inline-block;
    text-align: left;
    font: 16px "latoregular",Arial,Helvetica,sans-serif;
    line-height: 19px;
    margin-bottom: 9px;
    a {
      padding: 7px 10px;
      color: $txt-clr-sec;
      font: 16px $ff-lato-reg;
      &:hover, &.current {
        background: $sec-clr;
        color: #fff;
        text-decoration: none;
      }
    }
    .current-page {
      padding: 7px 10px;
      background: $sec-clr;
      color: #fff;
      text-decoration: none;
    }
  }
}

.articles-nav {
  @include centered-1240;
  font-size: 0;
  text-align: center;
  margin-top: 66px;
  li {
    vertical-align: top;
    display: inline-flex;
    max-width: 200px;
    &:first-child {
      margin-right: 16px;
    }
    a {
      border: 1px solid $trd-clr;
      background: #fff;
      padding: 15px;
      font: 17px $ff-agora-bld;
      color: $trd-clr;
      transition: background .3s;
      position: relative;
      &:hover {
        background: $trd-clr;
        color: #fff;
        text-decoration: none;
        &:before, &:after {
          @include sprite($article-arrow-white);
        }
      }
      &:before, &:after {
        position: absolute;
        @include sprite($article-arrow);
        content: '';
        display: inline-block;
        width: 15px;
        height: 25px;
      }
    }
  }
  li#{&}__before a {
    padding-left: 46px;    
    &:before {
      top: 50%;
      margin-top: -12px;
      left: 17px;
    }
    &:after {
      display: none;
    }
  }
  li#{&}__after a {
    padding-right: 46px;
    &:before {
      display: none;
    }
    &:after {
      top: 50%;
      margin-top: -12px;
      right: 17px;
      transform: rotate(180deg);
    }
  }
}

.individual-feedback {
  margin: 43px 0 35px;
  display: flex;
  align-items: center;
  img {
    margin-right: 2.25%;
    width: 162px;
    height: 162px;
    flex-basis: 162px;
    min-width: 162px;
    border-radius: 50%;
  }
  aside {
    padding-right: 11%;
  }
}

.feedback-form-wrap {
  @include centered-1240;
  border-bottom: 2px solid #c5c6ca;
  h3 {
    text-align: center;
  }
}

form.feedback-form {
  display: block;
  &.contacts-msg-form {
    margin: 40px 0;
  }
  fieldset {
    font-size: 0;
    label {
      display: inline-block;
      width: 48%;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  .feedback-txt-wrap {
    width: 100%;
    display: block;
    margin-top: 20px;
    textarea {
      display: block;
      width: 100%;
    }
  }
  .feedback-captcha-wrap {
    float: left;
    width: 50%;
    span {
      width: 100%;
      display: inline-block;
    }
    img {
      float: left;
    }
    & + [type=submit] {
      float: right;
      width: auto;
      margin-top: 29px;
    }
  }
}

.about-teaser {
  background: $txt-clr;
  padding: 20px 35px;
  margin: 0 0 38px;
  width: 64%;
  h4 {
    color: #fff;
    font: 30px $ff-agora-bld;
  }
}

.pics-box {
  font-size: 0;
  margin-bottom: 30px;
  img {
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.contacts-branch {
  margin: 20px auto 65px;
  @include centered-1240;
  .branch-info {
    display: flex;
    * {
      font: 16px $ff-lato-reg;
      color: $txt-clr;
    }
    .branch-address, .branch-phones, .branch-hours{
      padding-right: 3.2%;
    }
    .branch-address {
      font-style: normal;
      .branch-metro {
        display: block;
        margin-bottom: 6px;
        &:before {
          @include sprite($metro-icon);
          content: '';
          display: inline-block;
          margin-right: 8px;
          vertical-align: top;
        }
      }
      b {
        margin-left: 28px;
      }
    }
    .branch-phones {
      span {
        display: block;
        &:first-child {
          margin-bottom: 8px;
          &:before {
            @include sprite($phone-icon);
            content: '';
            display: inline-block;
            margin-right: 8px;
            vertical-align: top;
          }
        }
        &:last-child {
          margin-left: 24px;
        }
      }
    }
    .branch-hours {
      span{
        display: block;
        &:first-child {
          margin-bottom: 8px;
          &:before {
            @include sprite($watch-icon);
            display: inline-block;
            content: '';
            margin-right: 8px;
            vertical-align: top;
          }
        }
        &:last-child {
          margin-left: 24px;
        }
      }
    }
  }
  .branch-map-wrap {
    margin-top: 38px;
    width: 100%;
    height: 476px;
  }
}

.outside-map-lnk {
  display: none;
  font: 19px $ff-agora-bld;
  text-decoration: none;
  border-bottom: 1px dotted;
  color: $txt-clr-sec;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    border-color: #fff;
  }
}

.quarantees-wrap {
  @include centered-1240;
  font-size: 0;
  border-bottom: 2px solid #c5c6ca;
  figure {
    margin: 0 0 45px;
    display: inline-block;
    width: 25%;
    vertical-align: top;
    &:nth-child(3n + 2) {
      margin: 0 5% 45px;
    }
    img {
      margin-bottom: 16px;
    }
  }
}

.questions-wrap {
  margin-left: 4px;
  dt {
    margin: 0;
    h3 {
      line-height: 30px;
      &:before {
        content: '\003F';
        display: inline-block;
        font: 16px $ff-lato-reg;
        color: $txt-clr;
        border: 1px solid $txt-clr;
        border-radius: 50%;
        margin-right: 10px;
        vertical-align: top;
        padding: 4px 8px;
        text-align: center;
      }
    }
  }
  dd {
    margin: 0 0 34px;
    p {
      margin-left: 40px;
      border-left: 5px solid $sec-clr;
      padding: 18px 0 18px 25px;
    }
  }
}

.services-info {
  @include centered-1240;
  font-size: 0;
  figure {
    width: 32%;
    margin: 0 0 36px;
    display: inline-block;
    &:nth-child(3n+2) {
      margin: 0 2% 36px;
    }
    img {
      width: 100%;
    }
    a {
      position: relative;
      display: inline-block;
      width: 100%;
      &:hover {
        span {
          background: transparent;
          color: #e9cdcc;
        }
        &:after {
          display: inline-block;
        }
      }
      &:after {
        content: '';
        position: absolute;
        background: rgba(0, 0, 0, .6);
        display: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all .4s;
      }
      span {
        position: absolute;
        width: 90%;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        color: #fff;
        display: inline-block;
        font: 18px $ff-agora-serif;
        padding: 20px 5%;
        z-index: 1;
      }
    }
  }
}

.actions-wrap {
  @include centered-1240;
  .single-action {
    display: flex;
    margin-bottom: 46px;
    align-items: flex-start;
    img {
      border: 3px solid #d9d7cf;
      width: 23.790%;
    }
    .action-desc {
      padding: 0 3%;
      flex-grow: 1;
    }
    .action-validity {
      border: 2px solid #c5aaaa;
      width: 10%;
      min-width: 10%;
      padding: 16px 20px;
      min-height: 122px;
      &.closed {
        border-color: #a3a2a0;
        h5 {
          margin-top: 20px;
          color: #434343;
          &:before {
            @include sprite($calendar-closed);
            margin-bottom: 20px;
          }
        }
      }
      span {
        font: 16px $ff-lato-reg;
        text-align: center;
        display: block;
        margin-bottom: 4px;
      }
      h5 {
        font: 16px $ff-lato-black;
        text-align: center;
        color: $txt-clr-sec;
        margin-bottom: 12px;
        &:before {
          display: block;
          content: '';
          @include sprite($calendar);
          margin: 0 auto 13px;

        }
      }
    }
  }
}

.works-gallery-pics {
  @include centered-1240;
  .service-pics {
    margin-bottom: 70px;
    font-size: 0;
    h3 {
      margin-bottom: 24px;
    }
    figure {
      display: inline-block;
      width: 23.145%;
      margin: 0 2.25% 0 0;
      vertical-align: top;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        border: 2px solid #a9abae;
        position: relative;
        display: inline-block;
        &:hover {
          &:after {
            display: block;
          }
        }
        img {
          width: 100%;
        }
        &:after {
          display: none;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, .5) url(../../src/i/gallery-pic-overlay.png) 50% 50% no-repeat;
          z-index: 1;
          width: 100%;
          height: 100%;
        }
      }
      figcaption {
        text-align: center;
        font: 16px $ff-lato-reg;
        margin-top: 20px;
        span {
          display: block;
          margin-top: 6px;
        }
      }
    }
  }
}

@include keyframes(bg-fading) {
  0%   {background: rgba(85, 86, 89, 0);}
  100% {background: rgba(85, 86, 89, 1);}
}

@media screen and(min-width: 958px) and (max-width: 1256px) {
  .pricing-table {
    width: 90%;
    margin-bottom: 24px;
  }

  .main-header {
    @include centered-940;
    .logo-box {
      display: inline-block;
      width: 36.17%;
      em {
        display: block;
        margin-top: 8px;
      }
    }
    .top-phones {
      width: 63.82%;
        .branch-box:first-child {
          width: 50.8%;
        }
        .branch-box:last-child {
          width: 49.2%;
        }
      .branch-phones {
          span:first-child {
            margin-right: 2.5%;
          }
        }
      }
    .top-nav {
      //width: 69.8%;
      width: auto;
      flex-grow: 1;
    }
    .top-contact-box {
      width: auto;
      margin-top: 4px;
      display: inline-flex;
      width: 29.5%;
      align-items: center;
      a, .btn {
        white-space: nowrap;
      }
    }
  }
  .main-content {
    .works-gallery-pics {
      @include centered-940;
    }
    .services-info {
      @include centered-940;
      figure {
        &:nth-child(3n+2) {
          margin: 0 2% 36px;
        }
      }
    }
    .quarantees-wrap {
      @include centered-940;
      figure {
        width: 40%;
        padding-right: 10%;
        margin: 0 0 40px;
      }
    }
    .teaser-wrap {
      @include centered-940;
      max-height: 383px;
      margin-bottom: 30px;
      h3 {
        //top: 220px;
        span {
          font-size: 38px;
        }
      }
    }
    .gallery-headers {
      @include centered-940;
      &.fixed-nav {
        @include centered-940;
        margin: 0 auto 0 -470px;
        h3:nth-child(2) {
          margin: 0 2.098%;
        }
      }
    }
    .galleries-wrap {
      @include centered-940;
    }
    .about-box {
      width: 900px;
      article {
        width: 80%;
      }
    }
    .video-box {
      padding-bottom: 48px;
      @include centered-940;
    }
    .works-gallery {
      @include centered-940;
      padding-bottom: 65px;
      .works {
        width: 814px;
      }
    }
    .extra-cont-box {
      .cont-box {
        @include centered-940;
        padding: 0;
      }
    }
    .contacts-box {
      @include centered-940;
    }

    .feedback-form-wrap {
      @include centered-940;
    }
    .articles-nav {
      @include centered-940;
      margin-top: 66px;
    }
  }
  .main-footer {
    .footer {
      @include centered-940;
      .foot-nav {
        max-width: 25%;
      }
    }
  }
  .breadcrumbs {
    @include centered-940;
    margin-bottom: 34px;
  }
  .wysiwyg {
    @include centered-940;
    h1 {
      margin-top: 30px;
    }
    h3 {
      font-size: 24px;
    }
    .about-teaser {
      width: 84%;
    }
    .pics-box {
      img {
        max-width: 24%;
      }
    }
  }
  .actions-wrap, .paging {
    @include centered-940;
  }
  .action-desc {
    h3 {
      font-size: 22px;
    }
  }
  .contacts-branch {
    margin-bottom: 58px;
    @include centered-940;
  }
  .certificates-box {
    display: block;
    figure {
      display: inline-block;
    }
  }
}

@media screen and(min-width: 786px) and (max-width: 958px) {
  table.pricing-table {
    width: 100%;
    margin-bottom: 24px;
    table-layout: fixed;
    b {
      font-size: 13px;
    }
    tr {
      td {
        span.service-price {
          font-size: 15px;
        }
      }
    }
  }

  .bx-wrapper .bx-controls .bx-controls-direction a.bx-prev {
    top: 108%;
  }
  .main-header {
    @include centered-768();
    padding-bottom: 16px;
    display: block;
    position: relative;
    main.inner & {
      padding-top: 0;
      .top-nav.m-top-nav-visible {
        left: 0;
      }
    }
    .m-top-nav-toggle {
      display: inline-block;
    }
    .top-nav {
      display: none;
    }
    .logo-box {
      width: 60%;
      .main-logo {
      }
    }
    .top-phones {
      width: 78%;
      margin-top: 10px;
      .branch-phones {
        span {
          b {
            font-size: 18px;
          }
        }
      }
    }
    .top-contact-box {
      float: right;
      width: 22%;
      position: relative;
      & > a {
        position: relative;
        top: -40px;
        left: -47%;
      }
    }
  }
  .main-content {
    .works-gallery-pics {
      @include centered-768;
    }
    .services-info {
      @include centered-768;
      figure {
        span {
          padding: 10px 5%;
          font-size: 14px;
        }
      }
    }
    .quarantees-wrap {
      @include centered-768;
      figure {
        width: 40%;
        padding-right: 10%;
        margin: 0 0 40px;
      }
    }
    .teaser-wrap {
      @include centered-768;
      max-height: 296px;
      margin-bottom: 30px;
      h3 {
        //top: 162px;
        span {
          font-size: 29px;
        }
      }
    }
    .gallery-headers {
      @include centered-768;
      &.fixed-nav {
        width: 728px;
        //padding: 0 20px;
        margin-left: -364px;

      }
      h3 {
        a {
          font-size: 13px;
        }
      }
    }
    .galleries-wrap {
      @include centered-768;
      margin-top: 48px;
    }
    .about-box {
      @include centered-768;
      margin-top: 60px;
      padding: 48px 20px;
      article {
        width: 80%;
      }
    }
    .video-box {
      @include centered-768;
      display: block;
      margin: 40px auto 48px;
      div:first-child {
        margin: 0 0 40px;
        h3 {
          text-align: center;
        }
      }
    }
    .works-gallery {
      @include centered-768;
      margin-bottom: 39px;
      h2 {
        font-size: 45px;
      }
      .works {
        width: 610px;
        /*
        a {
          width: 100%;
          img {
            width: 100%;
          }
        }
        */
      }
    }
    .extra-cont-box {
      .cont-box {
        @include centered-768;
        display: block;
        .wysiwyg {
          width: 100% !important;
          &:first-child {
            margin-bottom: 58px;
          }
        }
      }
    }
    .contacts-box {
      @include centered-768;
      padding-top: 50px;
      h2 {
        font-size: 45px;
      }
    }
  }
  .main-footer {
    padding-top: 34px;
    .footer {
      @include centered-768;
      position: relative;
      .foot-nav {
        max-width: 25%;
      }
      .social-box {
        position: absolute;
        right: 20px;
        bottom: -20px;
      }
    }
  }
  .breadcrumbs {
    @include centered-768;
    margin-bottom: 34px;
  }
  .wysiwyg {
    @include centered-768;
    h1 {
      margin-top: 30px;
      font-size: 45px;
    }
    h3 {
      font-size: 24px;
    }
    p {
      margin-bottom: 10px;
    }
    .about-teaser {
      width: 100%;
      box-sizing: border-box;
      h4 {
        font-size: 26px;
      }
    }
    .pics-box {
      margin-top: 20px;
      img {
        max-width: 48%;
        margin-bottom: 2px;
      }
    }
  }
  .actions-wrap, .paging {
    @include centered-768;
    .single-action {
      .action-validity {
        h5 {
          font: 15px $ff-lato-reg;
          margin-bottom: 10px;
        }
        span {
          font: 13px $ff-lato-black;
        }
      }
    }
  }
  .action-desc {
    h3 {
      font-size: 20px;
    }
  }
  .articles-nav {
    @include centered-768;
    margin-top: 66px;
  }
  .contacts-branch {
    @include centered-768();
    margin-bottom: 28px;
    .branch-hours {
      clear: both;
      float: none;
      margin: 20px 0 10px;
    }
    .branch-info {
      display: block;
      overflow: hidden;
      .branch-address, .branch-phones, .branch-hours {
        float: left;
      }
      .branch-email {
        clear: both;
        display: block;
        margin: 50px 0 0 30px;
      }
    }
  }
  .feedback-form-wrap {
    @include centered-768;
  }
  .certificates-box {
    display: block;
    figure {
      display: inline-block;
      margin-bottom: 60px;
    }
  }
}

@media screen and(min-width: 481px) and (max-width: 786px) {
  .wysiwyg {
    .pricing-table {
      width: 100%;
      margin-bottom: 14px;
      table-layout: fixed;
      tr {
        td, th {
          padding: 8px;
          .service {
            margin-bottom: 0;
          }
          b {
            font-size: 12px;
          }
          .service-price {
            font-size: 13px;
          }
        }
      }
    }
  }
  .top-mobile-phone {
    display: inline-flex;
  }
  div.on-left, div.on-right {
    float: none;
    width: 100%;
    margin: 0;
  }
  .main-header {
    @include centered-480;
    padding: 16px 0;
    display: block;
    position: relative;
    main.inner & {
      padding-top: 12px;
      .m-top-nav-toggle {
        &.m-top-nav-close {
          right: 0;
        }
      }
    }
    .m-top-nav-toggle {
      display: inline-block;
      right: 0;
      top: 23px;
      &.m-top-nav-close {
        right: 0;
        top: 23px;
      }
    }
    .top-nav {
      display: none;
      &.m-top-nav-visible {
        width: 480px;
        left: -20px;
        padding: 64px 0 20px;
      }
      a {
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 16px;
        &:hover {
          background: $sec-clr;
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .logo-box {
      width: 92%;
      &.logo-box-visible {
        padding-left: 0;
      }
      .main-logo {
        font-size: 25px;
        margin-right: 4%;
      }
      em {
        font-size: 14px;
      }
    }
    .top-phones {
      display: none;
    }
    .top-contact-box {
      display: none;
    }
  }
  .main-content {
    .works-gallery-pics {
      @include centered-480;
      .service-pics {
        margin-bottom: 30px;
      }
    }
    .services-info {
      @include centered-480;
      figure {
        width: 45%;
        &:nth-child(3n+2) {
          margin: 0;
        }
        &:nth-child(2n+1) {
          margin-right: 10%;
        }
        span {
          padding: 10px 5%;
          font-size: 14px;
        }
      }
    }
    .quarantees-wrap {
      @include centered-480;
      figure {
        width: 90%;
        padding-right: 10%;
        margin: 0 0 40px;
      }
    }

    .breadcrumbs {
      display: none;
    }
    .teaser-wrap {
      width: 480px;
      margin: 0 auto 26px;
      .slides-wrap {
        max-height: 196px;
        overflow: hidden;
      }
      .owl-theme .owl-controls .owl-pagination .owl-page span {
        width: 15px;
        height: 2px;
      }
      h3 {
        //top: 90px;
        span {
          font-size: 21px;
        }
      }

    }
    .gallery-headers {
      display: block;
      @include centered-480;
      margin-bottom: 26px;
      &.fixed-nav {
        position: static;
        width: auto;
        margin-left: 0;
      }
      h3 {
        width: auto;
        margin-bottom: 14px;
        &:nth-child(2) {
          margin: 0 0 14px;
        }
      }
    }
    .galleries-wrap {
      display: none;
    }
    .about-box {
      @include centered-480;
      padding: 38px 20px 54px;
      h1 {
        font-size: 35px;
      }
      article {
        width: 72%;
      }
      .btn-sec {
        margin-top: 30px;
      }
    }
    .video-box {
      @include centered-480;
      display: block;
      padding: 40px 20px;
      div:first-child {
        margin: 0 0 30px;
      }
    }
    .works-gallery {
      @include centered-480;
      padding-bottom: 60px;
      h2 {
        font-size: 35px;
      }
      .works {
        width: 424px;
        .owl-controls {
          position: static;
          margin-top: 20px;
          .owl-buttons {
            .owl-prev, .owl-next {
              top: 100%;
            }
            .owl-prev {
              left: 0;
            }
            .owl-next {
              right: 0;
            }
          }
        }
      }
    }
    .extra-cont-box {
      .cont-box {
        @include centered-480;
        display: block;
        .wysiwyg {
          width: 100% !important;
          &:first-child {
            margin-bottom: 40px;
          }
        }
      }
    }
    .contacts-box {
      @include centered-480;
      padding-top: 40px;
      h2 {
        font-size: 35px;
      }
      address {
        max-width: 80%;
      }
      form.call-back-form {
        display: block;
        fieldset {
          width: auto;
          margin: 0;
          &:first-child {
            label:last-child {
              img {
                float: left;
                width: 50%;
              }
              input {
                float: right;

              }
            }
          }
          label {
            display: block;
          }
        }
      }
    }
  }
  .main-footer {
    .footer {
      @include centered-480;
      .foot-nav {
        border: none;
        padding: 20px 6% 20px 0;
        li {
          a {
            font-size: 22px;
          }
        }
      }
      .foot-services-nav {
        display: none;
      }
      .social-box {
        padding-top: 22px;
      }
    }
  }
  .wysiwyg {
    @include centered-480;
    h1 {
      font-size: 35px;
      margin-bottom: 14px;
    }
    .about-teaser {
      width: 100%;
      box-sizing: border-box;
      padding: 20px 24px;
    }
    h3 {
      font-size: 20px;
    }
    .full-width {
      margin-bottom: 14px;
    }
    h4 {
      font-size: 20px;
    }
    .pics-box {
      img {
        display: none;
        width: 100%;
        &:first-child {
          display: block;
        }
      }
    }

  }
  .actions-wrap, .paging {
    @include centered-480;
    .single-action {
      display: block;
      padding-bottom: 20px;
      border-bottom: 1px solid $txt-clr;
      img {
        width: 60%;
      }
      .action-desc {
        padding: 0;
      }
      .action-validity {
        width: 100%;
        box-sizing: border-box;
        border: none;
        padding-left: 0;
        h5 {
          font: 15px $ff-lato-reg;
          margin-bottom: 10px;
          text-align: left;
          &:before {
            margin: 0 0 15px;
          }
        }
        span {
          font: 13px $ff-lato-black;
          text-align: left;
        }
      }
    }
  }
  .action-desc {
    h3 {
      font-size: 20px;
    }
  }
  form.contacts-msg-form {
    fieldset {
      label {
        width: 100%;
      }
    }
    label.feedback-captcha-wrap {
      width: 100%;
      .captcha-pic+input[type=text] {
        float: left;
        margin-left: 40px;
      }
      & + [type=submit] {
            float: left;
          }
    }
  }
  /*
  .branch-map-wrap {
    display: none;
  }
  */
  .outside-map-lnk {
    display: inline-block;
    margin-bottom: 40px;
  }
  .contacts-branch {
    @include centered-480;
    .branch-info {
      display: block;
      .branch-address, .branch-phones, .branch-hours {
        padding-right: 0;
        margin-bottom: 20px;
      }
    }
    .branch-email {
      margin: 0 0 20px 28px;
      display: inline-block;
    }
  }
  .feedback-form-wrap {
    @include centered-480;
    .feedback-form {
      fieldset {
        label {
          width: 100%;
          &:first-child {
            margin-right: 0;
          }
        }
      }
      .feedback-captcha-wrap {
        width: 100%;
        .captcha-pic+input[type=text] {
          width: 70%;
          max-width: 240px;
        }
      }
    }
  }
  .individual-feedback {
    align-items: flex-start;
  }
  .articles-nav {
    @include centered-480;
    margin-top: 66px;
  }
  .certificates-box {
    display: block;
    figure {
      display: inline-block;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 481px) {
  .pricing-table {
    width: 100%;
    margin-bottom: 14px;
  }
  .top-mobile-phone {
    display: inline-flex;
  }
  div.on-left, div.on-right {
    float: none;
    width: 100%;
    margin: 0;
    clear: both;
  }
  .articles-nav {
    @include centered-320;
    margin-top: 26px;
    li {

      &:first-child {
        margin: 0 0 20px;
      }
      a {
        padding: 10px;
        font-size: 16px;
        display: block;

        &:visited, &:active {
          color: #9d8383;
        }
      }
    }
  }
  .btn-sec, .btn {
    font-size: 13px;
  }
  .main-header {
    @include centered-320;
    padding: 12px 0;
    display: block;
    position: relative;
    main.inner & {
      padding-top: 12px;
      .m-top-nav-toggle {
        &.m-top-nav-close {
          right: 0;
        }
      }
    }
    .m-top-nav-toggle {
      display: inline-block;
      right: 0;
      top: 20px;
      &.m-top-nav-close {
        right: 0;
        top: 23px;
      }
    }
    .top-nav {
      display: none;
      &.m-top-nav-visible {
        width: 320px;
        left: -20px;
        padding: 64px 0 20px;
      }
      a {
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 16px;
        &:hover {
          background: $sec-clr;
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .logo-box {
      width: 85%;
      &.logo-box-visible {
        padding-left: 0;
      }
      .main-logo {
        font-size: 26px;
      }
      em {
        display: none;
      }
    }
    .top-phones {
      display: none;
    }
    .top-contact-box {
      display: none;
    }
  }
  .teaser-wrap {
    h3 {
      //max-width: 50% !important;
      span {}

    }
  }
  .main-content {
    .works-gallery-pics {
      @include centered-320;
      .service-pics {
        margin-bottom: 30px;
      }
    }
    .services-info {
      @include centered-320;
      figure {
        width: 100%;
        &:nth-child(3n+2) {
          margin: 0 0 20px;
        }
        &:nth-child(2n+1) {
          margin-right: 0;
        }
        margin-bottom: 20px;
        span {
          padding: 10px 5%;
          font-size: 14px;
        }
      }
    }
    .quarantees-wrap {
      @include centered-320;
      figure {
        width: 90%;
        padding-right: 10%;
        margin: 0 0 40px;
      }
    }
    .breadcrumbs {
      display: none;
    }
    .teaser-wrap {
      width: 320px;
      margin: 0 auto 14px;
      max-height: 129px;
      h3 {
        //top: 27px;
        span {
          font-size: 15px;
          padding: 2px 10px;
        }
      }
      .owl-controls {
        display: none !important;
      }
    }
    .gallery-headers {
      @include centered-320;
      display: block;
      &.fixed-nav {
        position: static;
        left: 0;
        width: 280px;
        margin: 20px auto 0;
      }
      h3 {
        width: 100%;
        margin-bottom: 14px;
        &:nth-child(2) {
          margin: 0 0 14px;
        }
      }
    }
    .box-fixed-trigger {
      display: none;
    }
    .galleries-wrap {
      display: none;
    }
    .about-box {
      margin-top: 40px;
      @include centered-320;
      padding: 30px 20px 45px;
      h1 {
        font-size: 30px;
        & + img {
          float: none;
          margin-right: 0;
        }
      }
      article {
        margin: 0;
      }
      .btn-sec {
        margin-top: 10px;
      }
    }
    .video-box {
      @include centered-320;
      display: block;
      padding-top: 40px;
      h3 {
        text-align: center;
      }
      div:first-child {
        margin: 0 0 20px;
      }
    }
    .works-gallery {
      @include centered-320;
      padding-bottom: 60px;
      h2 {
        font-size: 30px;
      }
      .works {
        width: 200px;
        .owl-controls {
          display: none !important;
        }
      }
    }
    .extra-cont-box {
      .cont-box {
        @include centered-320;
        display: block;
        .wysiwyg {
          width: 100% !important;
          padding: 0;
          &:first-child {
            margin-bottom: 40px;
            p + .btn-sec {
              width: auto;
              text-align: center;
            }
          }
          h3 {
            font-size: 20px;
          }
          .btn-sec {
            width: auto;
          }
        }
      }
    }
    .contacts-box {
      @include centered-320;
      padding-top: 40px;
      h2 {
        font-size: 30px;
      }
      address {
        max-width: 80%;
        .contacts-phones {
          span:first-child {
            margin: 0;
          }
          span:last-of-type {
            display: block;
            margin-bottom: 10px;
          }
        }
      }
      form.call-back-form {
        display: block;
        input[type=submit] {
          font-size: 10px;
          margin-top: 0;
        }
        fieldset {
          width: auto;
          margin: 0;
          &:first-child {
            label:last-child {
              img {
                float: left;
                width: 100%;
              }
              input {
                float: right;
                width: 100%;
                max-width: none;
              }
            }
          }
          label {
            display: block;
          }
        }
      }
    }
  }
  .main-footer {
    min-height: 0;
    .footer {
      @include centered-320;
      .foot-nav {
        border: none;
        padding: 20px 6% 20px 0;
        li {
          a {
            font-size: 14px;
          }
          margin-bottom: 8px;
        }
      }
      .foot-services-nav {
        display: none;
      }
      .social-box {
        padding-top: 22px;
      }
    }
  }
  .wysiwyg {
    @include centered-320;
    h1 {
      font-size: 35px;
      margin-bottom: 14px;
    }
    .about-teaser {
      width: 100%;
      box-sizing: border-box;
      padding: 20px 24px;
    }
    h3 {
      font-size: 20px;
    }
    .full-width {
      margin-bottom: 14px;
    }
    h4 {
      font-size: 20px;
    }
    .pics-box {
      img {
        display: none;
        width: 100%;
        &:first-child {
          display: block;
        }
      }
    }
  }
  .actions-wrap, .paging {
    @include centered-320;
    .single-action {
      display: block;
      padding-bottom: 14px;
      border-bottom: 1px solid $txt-clr;
      margin-bottom: 20px;
      img {
        width: 100%;
        box-sizing: border-box;
      }
      .action-desc {
        padding: 0;
        h3 {
          font-size: 18px;
          margin: 10px 0;
        }
      }
      .action-validity {
        width: 100%;
        box-sizing: border-box;
        border: none;
        padding-left: 0;
        h5 {
          font: 15px $ff-lato-reg;
          margin-bottom: 10px;
          text-align: left;
          &:before {
            margin: 0 0 15px;
          }
        }
        span {
          font: 13px $ff-lato-black;
          text-align: left;
        }
      }
    }
  }
  form.contacts-msg-form {
    fieldset {
      label {
        width: 100%;
      }
    }
    label.feedback-captcha-wrap {
      width: 100%;
      .captcha-pic+input[type=text] {
        float: none;
        width: 100%;
        box-sizing: border-box;
        margin-left: 0;
      }
      & + [type=submit] {
            float: left;
          }
    }
  }
  /*
  .branch-map-wrap {
    display: none;
  }
  */
  .outside-map-lnk {
    display: inline-block;
    margin-bottom: 10px;
  }
  .contacts-branch {
    //@include centered-320;
    width: 100%;
    .branch-info {
      display: block;
      .branch-address, .branch-phones, .branch-hours {
        padding-right: 0;
        margin-bottom: 20px;
        b {
          margin-left: 20px;
        }
        .branch-metro{
          &:before {
            margin-right: 4px;
          }
        }
      }
    }
    .branch-email {
      margin: 0 0 20px 28px;
      display: inline-block;
    }
  }
  .feedback-form-wrap {
    @include centered-320;
    padding-top: 20px;
    h3 {
      font-size: 24px;
    }
    form {
      margin-top: 24px;
      fieldset {
        label {
          width: 100%;
        }
        label:first-child {
          margin-right: 0;
        }
      }
      .feedback-captcha-wrap {
        width: 100%;
        .captcha-pic+input[type=text] {
          float: none;
          width: 100%;
          max-width: none;
        }
      }
    }
  }
  .individual-feedback {
    display: block;
    img {
      margin-bottom: 14px;
    }
  }
  table.pricing-table {
    tr {
      td {
        padding: 6px 4px;
        b {
          font-size: 14px;
        }
        span {
          font-size: 13px;
        }
      }
    }
  }
  .certificates-box {
    display: block;
    figure {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 40px;
      &.horizontal-scope {
        a {
          background: none;
          height: auto;
          &:after {
            left: 0;
            top: 0;
            width: 100%;
            max-width: 100%;
            height: 198px;
          }
        }
      }
      a {
        background: none;
        max-width: 100%;
        width: auto;
        height: auto;
        display: inline-block;
        &:after {
          left: 0;
          top: 0;
          width: 100%;
          max-width: 100%;
        }
        img {
          left: 0;
          position: static;
        }
      }
      figcaption {
        text-align: left;
        margin-top: 0;

      }
    }
  }
  form.call-back-form {
    fieldset {
      width: auto;
      max-width: 206px;
      &:first-of-type {
        margin-right: 0;
      }
      input[type=submit] {
        white-space: normal;
      }
    }
  }

}

.top-nav {
  ul {
    //outline: $otl-red;
    display: flex;
    li {
      //outline: $otl-black;
      margin-right: 4%;
      &:last-child {
        margin-right: 0;
      }
      a {

      }
    }
  }
}

@media screen and(min-width: 481px) and (max-width: 786px) {
  .top-nav {
    ul {
      //outline: $otl-red;
      display: block;
      li {
        //outline: $otl-black;
        margin-right: 0;
      }
    }
  }
}

@media screen and(max-width: 481px) {
  form.call-back-form {
    margin-top: 10px;
  }
  .top-nav {
    ul {
      //outline: $otl-red;
      display: block;
      li {
        //outline: $otl-black;
        margin-right: 0;
      }
    }
  }
}


#gal_front_form_0 {
    max-width: none;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
}


#gal_front_form_1 {
    max-width: none;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
}

#gal_front_form_2 {
    max-width: none;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
}

#gal_front_form_3 {
    max-width: none;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
}

#gal_front_form_4 {
    max-width: none;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
}

#gal_front_form_5 {
    max-width: none;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
}

.removed {
  display: none;
}

.textwidget {
  padding: 10px 0;
}

.sub-head {
  display: block;
  margin: 20px 0 10px;
  font: 30px $ff-agora-bld;
  color: $txt-clr;
  text-decoration: none;
  &:hover {
    color: $sec-clr;
    text-decoration: underline;
  }
}

span.extra {
  display: block;
}

div.wpcf7-response-output {
  margin: 0 0 32px !important;
  font-size: 15px !important;
  color: #000 !important;
}

div.wpcf7-validation-errors {
  border-color: red !important;
  box-shadow: 0px 0px 10px red;
  color: red !important;
}

.bip-lnk {
  text-align: center;
  a {
    opacity: .5;
    transition: opacity .5s;
    &:hover {
      opacity: 1;
    }
  }
  img {
    width: 31px;
    height: 31px;
  }
}

.tparrows:before {
  color: $trd-clr !important;
}

.single-gallery__container {
  visibility: hidden;
}

.length-hair-wrap {
  padding: 40px 0 0;
  .extra-wrap {
    display: flex;
    align-items: flex-end;
    .sizes-wrap {
      min-width: 840px;
      height: 609px;
      background: url(../../src/i/shad-bg.png) 60% 100% no-repeat;
      position: relative;
      .bg-pic {
        width: 388px;
        height: 609px;
        margin: 0 auto;
        background: url(../../src/i/blond.png) 50% 100% no-repeat;
        transition: background 1s ease-in-out;
        &.sec-pic {
          background: url(../../src/i/brunette.png) 50% 100% no-repeat;
          transition: background 1s ease-in-out;
        }
      }
      .size-lines {
        width: 440px;
        height: 599px;
        background: url(../../src/i/size-lines.png) no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        b {
          font: 23px $ff-agora-bld;
          position: absolute;
          left: 0;
          cursor: pointer;
          display: block;
          min-width: 430px;
          &.stared {
            &:before {
              content: '';
              display: inline-block;
              background: url(../../src/i/star-icon.png) no-repeat;
              width: 22px;
              height: 20px;
              margin: -2px 8px 0 -20px;
            }
          }
          &:hover, &.current-size {
            color: #c5aaaa;
          }
          &.zero_size {
            top: -35px;
            &:hover {
              color: black;
              cursor: default;
            }
          }
          &.size_small {
            top: 268px;
          }
          &.size_middle {
            top: 368px;
          }
          &.size_long {
            top: 468px;
          }
          &.size_xl {
            top: 568px;
          }
        }
      }
    }
    .contacts-box {
      address {
        max-width: none;
        form {
          margin: 20px auto 0;
          &.focused {
            box-shadow: 0 0 12px #9d8383;
          }
          fieldset {
            width: auto;
            margin: 0;
          }
        }
      }
    }
  }
}

#size-holder {
  display: none;
}

.sizes-header {
  color: #9d8383;
  font-size: 20px;
  margin-top: 20px;
}


/*
@media screen and (max-width: 1280px) {
  .length-hair-wrap {
    display: none;
  }
}
*/

@media screen and(max-width: 1280px) {
  .length-hair-wrap {
    .extra-wrap {
      display: block;
      .sizes-wrap {
        min-width: 100%;
      }
      form {
        max-width: 319px;
      }

    }
    .contacts-box {
      width: auto;
      display: flex;
      justify-content: center;
      padding: 40px 0;
    }
    h2 {
      font-size: 36px;
      margin-bottom: 50px;
    }
  }
}

/*
@media screen and(max-width: 1006px) {
  .length-hair-wrap {
    display: none;
  }
}
*/

@media (max-width: 768px) {
  .main-content  {
    .extra-cont-box  {
      .cont-box  {
        .wysiwyg {
          box-sizing: border-box;
        }
      }
    }
  }
  .length-hair-wrap {
    .extra-wrap {
      .sizes-wrap {
        min-width: 100%;
        .size-lines  {
          b {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 482px) {
  .length-hair-wrap {
    padding-top: 20px;
    h2 {
      font-size: 24px;
    }
    .extra-wrap {
      .sizes-wrap {
        .bg-pic {
          width: 100%;
          &.sec-pic {

          }
        }
        .size-lines {
          width: 100%;
          b {
            font-size: 16px;
            text-align: center;
            color: #9d8383;
            min-width: 0;
            width: 100%;
          }
        }
      }
      .contacts-box {
        address {
          max-width: 100%;
          form {
            max-width: 206px;
            input[type=submit] {
              max-width: 206px;
              white-space: normal;
              margin-top: 0;
            }
            .ajax-loader {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width: 360px) {
  #wbounce-modal {
    .modal-body {
      form.wpcf7-form {
        display: block;
        box-sizing: content-box;
        fieldset {
          width: 100%;
          box-sizing: content-box;
        }
        input {
          width: 234px !important;
          &[type=submit] {
            white-space: normal;
          }
        }
      }
    }
  }
}

.extra-inner-nav {
  text-align: center;
  a {
    display: inline-block;
    padding: 4px 10px;
    margin: 10px;
    text-decoration: none;
    color: #c5aaaa;
    font-family: $ff-agora-bld;
    transition: background .7s;
    &:hover {
      background: #c5aaaa;
      color: #fff;
      border-radius: 4px;
    }
  }
}
