$otl-red: 1px solid red;
$otl-blue: 1px solid blue;
$otl-yellow: 1px dashed yellow;
$otl-black: 1px dotted black;


$txt-clr: #555659;
$txt-clr-sec: #363636;
$fst-clr: #ecefee;
$sec-clr: #c5aaaa;
$trd-clr: #9d8383;
$for-clr: #eae7e0;


$ff: Arial, Helvetica, sans-serif;
$ff-agora-med: 'pf_agora_slab_promedium', $ff;
$ff-agora-reg: 'pf_agora_serif_proregular', $ff;
$ff-agora-lgt: 'pf_agora_slab_prolight', $ff;
$ff-agora-bld: 'pf_agora_slab_probold', $ff;
$ff-agora-black: 'pf_agora_slab_problack', $ff;
$ff-annabelle: 'annabelleregular', $ff;
$ff-marta: 'martaitalic', $ff;
$ff-lato-reg: 'latoregular', $ff;
$ff-lato-black: 'latoblack', $ff;
$ff-lato-lgt: 'latolight', $ff;
$ff-agora-serif: 'pf_agora_serif_probold', $ff;
