@import "vars";

@mixin centered-1240() {
  //max-width: 1240px;
  width: 1240px;
  //padding: 0 20px;
  //padding: 0 1%;
  //width: 88%;
  margin: 0 auto;
}

@mixin centered-940() {
  //max-width: 1240px;
  width: 940px;
  //padding: 0 20px;
  //padding: 0 1%;
  //width: 88%;
  margin: 0 auto;
}

@mixin centered-768() {
  //max-width: 1240px;
  width: 728px;
  padding: 0 20px;
  //padding: 0 1%;
  //width: 88%;
  margin: 0 auto;
}

@mixin centered-480() {
  //max-width: 1240px;
  width: 440px;
  padding: 0 20px;
  //padding: 0 1%;
  //width: 88%;
  margin: 0 auto;
}

@mixin centered-320() {
  //max-width: 1240px;
  width: 280px;
  padding: 0 20px;
  //padding: 0 1%;
  //width: 88%;
  margin: 0 auto;
}

@mixin rounded($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}

@mixin shadow($x, $y, $blur, $color) {
  -webkit-box-shadow: $x $y $blur $color;
     -moz-box-shadow: $x $y $blur $color;
          box-shadow: $x $y $blur $color;
}

@mixin shadow-inset($x, $y, $blur, $color) {
  -webkit-box-shadow: inset $x $y $blur $color;
     -moz-box-shadow: inset $x $y $blur $color;
          box-shadow: inset $x $y $blur $color;
}

@mixin box-sizing {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

@mixin linear-gradient($from, $to) {
  /* Fallback for sad browsers */
  background-color: $to;
  /* Mozilla Firefox */
  background-image:-moz-linear-gradient($from, $to);
  /* Opera */
  background-image:-o-linear-gradient($from, $to);
  /* WebKit (Chrome 11+) */
  background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop(1, $to));
  /* WebKit (Safari 5.1+, Chrome 10+) */
  background-image: -webkit-linear-gradient($from, $to);
  /* IE10 */
  background-image: -ms-linear-gradient($from, $to);
  /* W3C */
  background-image: linear-gradient($from, $to);
}

// generic transform
@mixin transform($transforms) {
  -webkit-transform: $transforms;
     -moz-transform: $transforms;
       -o-transform: $transforms;
          transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}



/**
 * rem mixins with pixel fallback for < IE9 and Opera Mini
 */

@function rem($pixel_value) {

  @if type-of( $pixel-value ) == 'string' {
    @return $pixel_value;
  }

  @if unitless($pixel_value) {
    @return $pixel_value;
  }

  @if unit( $pixel_value ) == 'px' {
    @return ($pixel_value/16px)+rem;
  }
}

@mixin font($font-size, $family) {
  font: $font-size $family;
  font: rem($font-size) $family;
}

@mixin font-size($font-size) {
  font-size: $font-size;
  font-size: rem($font-size);
}


/* MARGINS */

@mixin margin($values) {
  @if type-of($values) == 'number' {
    margin: $values;
    margin: rem($values);
  } @else {
    $rem_values: ();
    @each $value in $values {
      @if $value == 0 {
        $rem_values: append($rem_values, $value);
      } @else {
        $rem_values: append($rem_values, rem($value));
      }

    }
    margin: $values;
    margin: $rem_values;
  }
}

@mixin margin-top($margin-top) {
  margin-top: $margin-top;
  margin-top: rem($margin-top);
}

@mixin margin-right($margin-right) {
  margin-right: $margin-right;
  margin-right: rem($margin-right);
}

@mixin margin-bottom($margin-bottom) {
  margin-bottom: $margin-bottom;
  margin-bottom: rem($margin-bottom);
}

@mixin margin-left($margin-left) {
  margin-left: $margin-left;
  margin-left: rem($margin-left);
}

/* PADDINGS */

@mixin padding($values) {
  @if type-of($values) == 'number' {
    padding: $values;
    padding: rem($values);
  } @else {
    $rem_values: ();
    @each $value in $values {
      @if $value == 0 {
        $rem_values: append($rem_values, $value);
      } @else {
        $rem_values: append($rem_values, rem($value));
      }
    }
    padding: $values;
    padding: $rem_values;
  }
}

@mixin padding-top($padding-top) {
  padding-top: $padding-top;
  padding-top: rem($padding-top);
}

@mixin padding-right($padding-right) {
  padding-right: $padding-right;
  padding-right: rem($padding-right);
}

@mixin padding-bottom($padding-bottom) {
  padding-bottom: $padding-bottom;
  padding-bottom: rem($padding-bottom);
}

@mixin padding-left($padding-left) {
  padding-left: $padding-left;
  padding-left: rem($padding-left);
}


@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

/*for retina displays*/

@mixin image-bigDppx($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}
